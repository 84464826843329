import React from "react";

export default function Modal({ isCorrect, turn, solution }) {
  return (
    <div className="modal">
      {isCorrect && (
        <div>
          <h1>You Win!</h1>
          <p className="solution">{solution}</p>
          <p>You found the solution in {turn} guesses :)</p>
          <a href="/">Play Again</a>
        </div>
      )}

      {!isCorrect && (
        <div>
          <h1>You lose :(</h1>
          <p className="solution">{solution}</p>
          <p>Missed it this time!</p>
          <a href="/">Play Again</a>
        </div>
      )}
    </div>
  );
}
