import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import jsondb from "./db.json";

export default function Keypad({ usedKeys, handleKeyup }) {
  const [letters, setLetters] = useState(null);

  // useEffect(() => {
  //   fetch("http://localhost:3001/letters")
  //     .then((res) => res.json())
  //     .then((json) => {
  //       setLetters(json);
  //     })
  //     .catch((error) => {
  //       setLetters(dbletters.letters);
  //     });
  // }, []);

  // useEffect(() => {
  //   fetch("http://localhost:3001/letters")
  //     .then((res) => res.json())
  //     .then((json) => {
  //       setLetters(json);
  //     });
  // }, []);

  useEffect(() => {
    setLetters(jsondb.letters);
  }, []);

  // simulate a keypress if the keyboard is clicked
  const sendKeyPress = (k) => {
    handleKeyup({ key: k.target.innerText.toLowerCase() });
  };

  return (
    <div className="keypad">
      {letters &&
        letters.map((l) => {
          const color = usedKeys[l.key];
          return (
            <div key={l.key} className={color} onClick={sendKeyPress}>
              {l.key.toUpperCase()}
            </div>
          );
        })}
    </div>
  );
}
